import http from "../http";

// export all dashboard services 
export const dashboardService = {
    getTimelineListService,
    getAllNotificationsService,
    readNotificationService,
    getTodosListService,
    updateTodoListService,
    getAllEvents,
    createNewEvent,
    editEvent,
    getReminderAndContactList,
    calenderAuthorization
}

// get timeline data service
function getTimelineListService({userId, payload}: any) {
    return http.post(`${process.env.REACT_APP_BASE_URL}/designer/${userId}/dashboard`,
    payload,
    {
        headers: { "Content-Type": "multipart/form-data" },
    }
    ); // user-id is hard coded for now.
}

// get todos data service
function getTodosListService({userId, payload}: any) {
    return http.post(`${process.env.REACT_APP_BASE_URL}/designer/${userId}/dashboard/todo-list`,
    payload,
    {
        headers: { "Content-Type": "multipart/form-data" },
    }
    ); // user-id is hard coded for now.
}

// update todo service
function updateTodoListService(payload: any) {
    return http.put(`${process.env.REACT_APP_BASE_URL}/designer/dashboard/edit-todo-list`, {
        ...payload.data
    });
}

// read notification
function readNotificationService(payload:any) {
    return http.put(`${process.env.REACT_APP_BASE_URL}/designer/${payload?.userId}/dashboard/notification/${payload?.notification_id}`); // id 32116966 is hard coded, need to change after complete developement.
}

// get all notifications 
function getAllNotificationsService(userId:string) {
    return http.get(`${process.env.REACT_APP_BASE_URL}/designer/${userId}/dashboard/notification`);
}

// get all events 
function getAllEvents(designer_id:any) {
    return http.post(`${process.env.REACT_APP_BASE_URL}/calendar/${designer_id}/calendar-event`);
}

// create new event
function createNewEvent({ payload, designer_id }: any) {
    return http.post(`${process.env.REACT_APP_BASE_URL}/calendar/${designer_id}/create-calendar-event`,
        payload,
        {
            headers: { "Content-Type": "multipart/form-data" },
        }
    )
}

// create new event
function editEvent({ payload, designer_id }: any) {
    return http.put(`${process.env.REACT_APP_BASE_URL}/calendar/${designer_id}/edit-calendar-event`,
        payload,
        {
            headers: { "Content-Type": "multipart/form-data" },
        }
    )
}

// get reminder and contact list
function getReminderAndContactList() {
    return http.get(`${process.env.REACT_APP_BASE_URL}/calendar/get-reminder-contacts`);
}

// get reminder and contact list
function calenderAuthorization(payload: any, designer_id: any) {
    return http.post(`${process.env.REACT_APP_BASE_URL}/calendar/${designer_id}/calendar-auth-code`, {
        ...payload
    }
    )
}

// project manager ID - 32116966 has been hard-coded. needs to be dyncamically fed based on user login details.