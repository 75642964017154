import { createAsyncThunk } from '@reduxjs/toolkit';
import { dashboardService } from '../../../services/designManagement/dashboard.service';
import { showHideLoader } from '../../common/common.slice';


// Get timeline list action
const getTimelineListAction = createAsyncThunk('dashboardSlice/getTimelineListAction', ({userId, payload}: any, {dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));
    return dashboardService.getTimelineListService({userId, payload})
        .then((response) => {
            setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500);
            return response;
        })
        .catch((error: {response: unknown;}) => {
            setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500);
            return rejectWithValue(error?.response)
        })
});

// Get notification action
const getAllNotificationsAction = createAsyncThunk('dashboardSlice/getAllNotificationsAction', (userId:string, {dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));
    return dashboardService.getAllNotificationsService(userId)
        .then((response) => {
            setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500);
            return response;
        })
        .catch((error: {response: unknown;}) => {
            setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500);
            return rejectWithValue(error?.response)
        })
});

// Get todos list action
const getTodosListAction = createAsyncThunk('dashboardSlice/getTodosListAction', ({userId,payload}: any, {dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));
    return dashboardService.getTodosListService({userId, payload})
        .then((response) => {
            setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500);
            return response;
        })
        .catch((error: {response: unknown;}) => {
            setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500);
            return rejectWithValue(error?.response)
        })
});

// update todo action
const updateTodoAction = createAsyncThunk('dashboardSlice/updateTodoAction', (payload:any, {dispatch, rejectWithValue}) =>{
    if(payload?.loader){
        dispatch(showHideLoader(true));
    }
    return dashboardService.updateTodoListService(payload)
        .then((response) => {
            if(payload?.loader){
                dispatch(showHideLoader(false));
            }
            return response;

        })
        .catch((error: {response: unknown;}) => {
            if(payload?.loader){
                dispatch(showHideLoader(false));
            }
            return rejectWithValue(error?.response)
        })
});

// update timelinetask action
const updateTimelineTaskAction = createAsyncThunk('dashboardSlice/updateTimelineTaskAction', (payload:any, {dispatch, rejectWithValue}) =>{
    if(payload?.loader){
        dispatch(showHideLoader(true));
    }
    return dashboardService.updateTodoListService(payload)
        .then((response) => {
            if(payload?.loader){
                dispatch(showHideLoader(false));
            }
            return response;

        })
        .catch((error: {response: unknown;}) => {
            if(payload?.loader){
                dispatch(showHideLoader(false));
            }
            return rejectWithValue(error?.response)
        })
})

// read notification action
const readNotificationAction = createAsyncThunk('dashboardSlice/readNotificationAction', (payload:any, {dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));
    return dashboardService.readNotificationService(payload)
        .then((response) => {
            setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500);
            return response;
        })
        .catch((error: {response: unknown;}) => {
            setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500);
            return rejectWithValue(error?.response)
        })
})

// get all event action
const getAllEventAction = createAsyncThunk('dashboardSlice/getAllEventAction', (designer_id:any, {dispatch, rejectWithValue}) =>{
    dispatch(showHideLoader(true));
    return dashboardService.getAllEvents(designer_id)
        .then((response) => {
            setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500);
            return response;
        })
        .catch((error: {response: unknown;}) => {
            setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500);
            return rejectWithValue(error?.response)
        })
})

// create new event action
const createNewEventAction = createAsyncThunk('dashboardSlice/createNewEventAction', ({ payload, designer_id }: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return dashboardService.createNewEvent({ payload, designer_id })
        .then((response) => {
            setTimeout(() => {
                dispatch(showHideLoader(false))
            }, 500);
            return response;
        })
        .catch((error: { response: unknown; }) => {
            setTimeout(() => {
                dispatch(showHideLoader(false))
            }, 500);
            return rejectWithValue(error?.response)
        })
})

// Edit event action
const editEventAction = createAsyncThunk('dashboardSlice/editEventAction', ({ payload, designer_id }: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return dashboardService.editEvent({ payload, designer_id })
        .then((response) => {
            setTimeout(() => {
                dispatch(showHideLoader(false))
            }, 500);
            return response;
        })
        .catch((error: { response: unknown; }) => {
            setTimeout(() => {
                dispatch(showHideLoader(false))
            }, 500);
            return rejectWithValue(error?.response)
        })
})

// get reminder and contact list action
const getReminderAndContactListAction = createAsyncThunk('dashboardSlice/getReminderAndContactListAction', (payload, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return dashboardService.getReminderAndContactList()
        .then((response) => {
            setTimeout(() => {
                dispatch(showHideLoader(false))
            }, 500);
            return response;
        })
        .catch((error: { response: unknown; }) => {
            setTimeout(() => {
                dispatch(showHideLoader(false))
            }, 500);
            return rejectWithValue(error?.response)
        })
})

// outlook authorization action
const outlookAuthorizationAction = createAsyncThunk('dashboardSlice/outlookAuthorizationAction', ({payload, designer_id}:any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return dashboardService.calenderAuthorization(payload, designer_id)
        .then((response) => {
            setTimeout(() => {
                dispatch(showHideLoader(false))
            }, 500);
            return response;
        })
        .catch((error: { response: unknown; }) => {
            setTimeout(() => {
                dispatch(showHideLoader(false))
            }, 500);
            return rejectWithValue(error?.response)
        })
})


// Export all Projects Action
export const dashboardActions = {
    getTimelineListAction,
    getAllNotificationsAction,
    readNotificationAction,
    getTodosListAction,
    updateTodoAction,
    updateTimelineTaskAction,
    getAllEventAction,
    createNewEventAction,
    getReminderAndContactListAction,
    editEventAction,
    outlookAuthorizationAction
}