import { lazy } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { Navigate } from "react-router-dom";
import {comminUtils} from "../Utils/utils";
import {userObject} from "../redux/auth/auth.selectors";
import { useAppSelector } from "../redux/store/store";
const AccessDenied = lazy(() => import("../pages/Authentication/AccessDenied/Index.accessDenied"));
const MobileStatic = lazy(() => import("../pages/Authentication/MobileStaticPage/Index.mobileStaticPage"));


export type ProtectedRouteProps = {
  isAuthenticated: boolean;
  authenticationPath: string;
  module_name: string;
  permissions: Array<string>;
  outlet: JSX.Element;
};

export default function ProtectedRoute({isAuthenticated, authenticationPath, outlet, module_name, permissions}: ProtectedRouteProps) {
  const userPermissions:any = useAppSelector(userObject);
  let is_module_accessible = false;
  // Authorized condition to redirect the user to login
  if(userPermissions){
    // is_module_accessible = comminUtils.isAccessible(module_name, userPermissions, permissions);
    is_module_accessible = comminUtils.checkPermission(module_name, permissions[0], userPermissions?.permissions);
  }
  if(is_module_accessible) {
    // temporarily, for mobile view, check if the route contains "pm/projects/details" 
    
    if(isMobile && !isTablet){
      const route = "projectManagement/projects/details/";
      if (window.location.pathname?.includes(route)) {
        return outlet;
      }else{
        // render static page
        return <MobileStatic/>
      }
    }else{
      return outlet;
    }
  } else if(window.location.pathname !== "/login"){
    // need to render a static page indicating that the user does not have access to this page
    return (
      <AccessDenied/>
    )
  }else {
    return <Navigate to={{ pathname: "/login" }} />;
  }
};