import http from "../http";

// Export all Proposal Service
export const proposalServices = {
  proposalsListService,
  proposalDetailsService,
  proposalsAutocompleteService,
  getProjectContactDetailsService,
  createProposalService,
  savePaymentScheduleService,
  getPaymentScheduleService,
  getTermsService,
  saveCustomExclusionService,
  saveNotesService,
  getMasterItemsService,
  proposalItemDetailsService,
  uploadPolyModelService,
  uploadProjectLayoutService,
  removeProjectLayoutService,
  addProposalMemoService,
  saveItemDetailsService,
  saveRoomDetailsService,
  getItemDetailsService,
  saveItemInfoService,
  exportPDFService,
  countersignProposalService,
  unreleaseProposalService,
  templateProposalService,
  uploadPdfForSignProposal,
  createdProposalsListService,
  selectedProposalRoomListService,
  importRoomService,
  proposalExportService,
  proposalReduceScopeService,
  cancelProposalService,
  proposalExternalSignLinkService
};

// Sales Proposals list Service
function proposalsListService(payload: any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/sales/proposal/list-proposals?page=${payload.page}&perPage=${payload.perPage}`, {
    filters:payload.filters,
    sort:payload.sort
  });
}

// Sales Proposals Autocomplete service
function proposalsAutocompleteService(search: string) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/sales/proposal/autcomplete?term=${search}`);
}

// Check project contact details
function getProjectContactDetailsService(projectId:any) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/sales/project/${projectId}/contact`);
}

// Sales Proposal create service
function createProposalService(payload: any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/sales/proposal`, {
    dealName: payload.dealName,
    dealId: payload.dealId,
    address: payload.address,
    notes: payload.notes,
    updatedBy: payload.updatedBy,
    items: payload.items,
  })
}

// Sales Proposals details service
function proposalDetailsService( proposalId: string) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${proposalId}`)
}

// Get payment schedule details service
function getPaymentScheduleService(proposalId: string) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/sales/proposal/get-payment-schedule/${proposalId}`)
}

// Get terms service
function getTermsService(proposalId: string) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/sales/proposal/get-terms/${proposalId}`)
}

// save custom exclusion
function saveCustomExclusionService(payload: any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${payload?.proposalId}/create-terms`, payload?.data)
}

// save payment schedule details service
function savePaymentScheduleService(payload:any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/sales/proposal/save-payment-schedule`,{...payload})
}

// save notes service
function saveNotesService(payload:any) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/sales/proposal/save-notes`,{...payload})
}

// get master items
function getMasterItemsService(dealId: string) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${dealId}/get-master-items`);
}
// Sales Proposals Item Details service
function proposalItemDetailsService( payload : any) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${payload.proposalId}/items?sort=${payload.room}`)
}

// upload polycam model service
function uploadPolyModelService(payload:any) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${payload.proposalId}/group/${payload.groupId}/model-upload`,
    payload?.data,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  )
}

// upload project layout model service
function uploadProjectLayoutService(payload:any) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${payload.proposalId}/model-upload`,
    payload?.data,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  )
}

// upload project layout model service
function removeProjectLayoutService(payload:any) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${payload.proposalId}/model-upload`,
    {
      fileType: "models",
      delete: true
    },
  )
}

// add memo
function addProposalMemoService(payload:any) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/projects/${payload?.dealId}/memo`,
    {...payload?.data}
  )
}

// save item details service
function saveItemDetailsService(payload:any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/sales/proposal/save-items`,{...payload?.data})
}

// save room details service
function saveRoomDetailsService(payload:any) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${payload?.id}/group/${payload?.groupId}`,
    {...payload?.data}
  )
}

// get item details service
function getItemDetailsService(payload:any) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${payload.proposalId}/items/${payload.itemId}?groupId=${payload.groupId}`)
}

// save item info service
function saveItemInfoService(payload:any) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${payload.proposalId}/group/${payload.groupId}/items/${payload.itemId}`, payload.item)
}

// export pdf service
function exportPDFService(payload:any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/sales/proposal/pdf`, payload)
}

// countersignProposalService
function countersignProposalService(payload:any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${payload?.id}/link`, {
    role: payload?.role
  })
}

// unrelease proposal service
function unreleaseProposalService(id:any) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${id}/unrelease`)
}

// proposal template service
function templateProposalService(payload:any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${payload?.id}/template/${payload?.dealId}`, payload.object)
}

// direct sign proposal service
function uploadPdfForSignProposal({payload,proposalId}: any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${proposalId}/sign`, 
    payload,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  )
}

// proposal autocomplete service
function createdProposalsListService (search:any) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/sales/proposal/autcomplete/import?term=${search}`)
}

// proposals room list service
function selectedProposalRoomListService (proposalId:any) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${proposalId}/room`)
}

// import room service
function importRoomService (payload:any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${payload?.id}/import-room`, payload?.object)
}

// proposal export service
function proposalExportService (id:any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${id}/export`)
}

// proposal reduce scope service
function proposalReduceScopeService(payload:any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${payload?.id}/reduce-scope`, payload?.data)
}

// cancel proposal service
function cancelProposalService(payload: any){

  if(payload?.data){
    return http.post(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${payload?.id}/cancel`,
      payload?.data,
    );
  }
  return http.post(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${payload?.id}/cancel`);
}

// proposal get external sign link service
function proposalExternalSignLinkService(payload:any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/signnow/external-signature-link`, payload);
}




