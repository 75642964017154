import { createAsyncThunk } from "@reduxjs/toolkit";
import { proposalServices } from "../../../services/sales/proposal.services";
import { showHideLoader } from "../../common/common.slice";

// get proposals list action
const getProposalsListAction = createAsyncThunk(
  'salesSlice/getProposalsListAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.proposalsListService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// get proposales autopcomplete action
const proposalsAutocompleteAction = createAsyncThunk(
  'salesSlice/proposalsAutocompleteAction', (search: string, { dispatch, rejectWithValue }) => {
    // dispatch(showHideLoader(true));
    return proposalServices.proposalsAutocompleteService(search)
      .then((response) => {
        // setTimeout(() => {
        //   dispatch(showHideLoader(false))
        // }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        // setTimeout(() => {
        //   dispatch(showHideLoader(false))
        // }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// check project contact details
const getProjectContactDetailsAction = createAsyncThunk(
  'salesSlice/getProjectContactDetailsAction', (projectId: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.getProjectContactDetailsService(projectId)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// create proposal action
const createProposalAction = createAsyncThunk(
  'salesSlice/createProposalAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.createProposalService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// get proposal details action
const getProposalDetailsAction = createAsyncThunk(
  'salesSlice/getProposalDetailsAction', (proposalId: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.proposalDetailsService(proposalId)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// get proposal Item Details action
const getProposalItemDetailsAction = createAsyncThunk(
  'salesSlice/getProposalItemDetailsAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.proposalItemDetailsService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// get proposal room Item Details for polycam overview
const getProposalPolycamDetailsAction = createAsyncThunk(
  'salesSlice/getProposalPolycamDetailsAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.proposalItemDetailsService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// get terms action
const getTermsAction = createAsyncThunk(
  'salesSlice/getTermsAction', (proposalId: string, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.getTermsService(proposalId)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// save custom exclusion
const saveCustomExclusionAction = createAsyncThunk(
  'salesSlice/saveCustomExclusionAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.saveCustomExclusionService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// save payment schedule
const savePaymentScheduleAction = createAsyncThunk(
  'salesSlice/savePaymentScheduleAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.savePaymentScheduleService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// get payment schedule
const getPaymentScheduleAction = createAsyncThunk(
  'salesSlice/getPaymentScheduleAction', (proposalId: string, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.getPaymentScheduleService(proposalId)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// Save notes
const saveNotesAction = createAsyncThunk(
  'salesSlice/saveNotesAction', (payload: any, { dispatch, rejectWithValue }) => {
    // dispatch(showHideLoader(true));
    return proposalServices.saveNotesService(payload)
      .then((response) => {
        // setTimeout(() => {
          // dispatch(showHideLoader(false))
        // }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        // setTimeout(() => {
          // dispatch(showHideLoader(false))
        // }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// get proposals master items
const getMasterItemsAction = createAsyncThunk(
  'salesSlice/getMasterItemsAction', (dealId:string, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.getMasterItemsService(dealId)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// Save proposal items
const saveProposalItemsAction = createAsyncThunk(
  'salesSlice/saveProposalItemsAction', (payload: any, { dispatch, rejectWithValue }) => {
    
    if(payload?.loader){
      dispatch(showHideLoader(true));
    }
    return proposalServices.saveItemDetailsService(payload)
      .then((response) => {
        // removing loader function here.
        // need to make sure that everytime save list is called, after its response new list needs to be fetched.
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// Upload polycam model file action
const uploadPolyModelAction = createAsyncThunk(
  'salesSlice/uploadPolyModelAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.uploadPolyModelService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// Upload project layout action
const uploadProjectLayoutlAction = createAsyncThunk(
  'salesSlice/uploadProjectLayoutlAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.uploadProjectLayoutService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// Upload project layout action
const removeProjectLayoutlAction = createAsyncThunk(
  'salesSlice/removeProjectLayoutlAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.removeProjectLayoutService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// Add proposal memo action
const addProposalMemolAction = createAsyncThunk(
  'salesSlice/addProposalMemolAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.addProposalMemoService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// Save proposal items
const saveRoomDetailsAction = createAsyncThunk(
  'salesSlice/saveRoomDetailsAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.saveRoomDetailsService(payload)
      .then((response) => {
        // removing loader function here.
        // need to make sure that everytime save list is called, after its response new list needs to be fetched.
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// get proposal item
const getProposalItemAction = createAsyncThunk(
  'salesSlice/getProposalItemAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.getItemDetailsService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// save proposal item info
const saveProposalItemInfoAction = createAsyncThunk(
  'salesSlice/saveProposalItemInfo', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.saveItemInfoService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// save proposal item info
const exportPDFAction = createAsyncThunk(
  'salesSlice/exportPDFAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.exportPDFService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// counter sign proposal
const countersignProposalAction = createAsyncThunk(
  'salesSlice/countersignProposalAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.countersignProposalService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// unrelease proposal
const unreleaseProposalAction = createAsyncThunk(
  'salesSlice/unreleaseProposalAction', (id: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.unreleaseProposalService(id)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// proposal template
const templateProposalAction = createAsyncThunk(
  'salesSlice/templateProposalAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.templateProposalService(payload)
      .then((response) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return response
      })
      .catch((error: { response: unknown; }) => {
        setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
        return rejectWithValue(error?.response)
      })
  }
);

// Upload pdf for sign proposal Action
const uploadPdfForSignProposalAction = createAsyncThunk(
  'proposalSlice/uploadPdfForSignProposalAction', ({payload,proposalId}:any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.uploadPdfForSignProposal({payload,proposalId})
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
  }
);

// created proposal autocomplete list Action
const createdProposalsListAction = createAsyncThunk(
  'proposalSlice/createdProposalsListAction', (search:string, { dispatch, rejectWithValue }) => {
    // dispatch(showHideLoader(true));
    return proposalServices.createdProposalsListService(search)
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
  }
);

// selected proposal room list Action
const selectedProposalRoomListAction = createAsyncThunk(
  'proposalSlice/selectedProposalRoomListAction', (proposalId:string, { dispatch, rejectWithValue }) => {
    // dispatch(showHideLoader(true));
    return proposalServices.selectedProposalRoomListService(proposalId)
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
  }
);

// Importroom Action
const importRoomAction = createAsyncThunk(
  'proposalSlice/importRoomAction', (payload:any, { dispatch, rejectWithValue }) => {
    // dispatch(showHideLoader(true));
    return proposalServices.importRoomService(payload)
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
  }
);

// Export Action
const proposalExportAction = createAsyncThunk(
  'proposalSlice/proposalExportAction', (payload:any, { dispatch, rejectWithValue }) => {
    // dispatch(showHideLoader(true));
    return proposalServices.proposalExportService(payload)
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
  }
);

// proposal reduce scope Action
const proposalReduceScopeAction = createAsyncThunk(
  'proposalSlice/proposalReduceScopeAction', (payload:any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.proposalReduceScopeService(payload)
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
  }
);

// proposal cancel Action
const cancelProposalAction = createAsyncThunk(
  'proposalSlice/cancelProposalAction', (payload:any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.cancelProposalService(payload)
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
  }
);

// proposal cancel Action
const externalSignLinkAction = createAsyncThunk(
  'proposalSlice/externalSignLinkAction', (payload:any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return proposalServices.proposalExternalSignLinkService(payload)
    .then((response) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return response
    })
    .catch((error: { response: unknown; }) => {
      setTimeout(() => {
          dispatch(showHideLoader(false))
        }, 500)
      return rejectWithValue(error?.response)
    })
  }
);

export const salesProposalsActions = {
  getProposalsListAction,
  proposalsAutocompleteAction,
  getProjectContactDetailsAction,
  createProposalAction,
  getProposalDetailsAction,
  savePaymentScheduleAction,
  getPaymentScheduleAction,
  getTermsAction,
  saveCustomExclusionAction,
  saveNotesAction,
  getMasterItemsAction,
  getProposalItemDetailsAction,
  saveProposalItemsAction,
  uploadPolyModelAction,
  uploadProjectLayoutlAction,
  removeProjectLayoutlAction,
  addProposalMemolAction,
  saveRoomDetailsAction,
  getProposalItemAction,
  saveProposalItemInfoAction,
  exportPDFAction,
  countersignProposalAction,
  unreleaseProposalAction,
  templateProposalAction,
  getProposalPolycamDetailsAction,
  uploadPdfForSignProposalAction,
  createdProposalsListAction,
  selectedProposalRoomListAction,
  importRoomAction,
  proposalExportAction,
  proposalReduceScopeAction,
  cancelProposalAction,
  externalSignLinkAction
}
